const email: string = "bW9jLmVyYXd0Zm9zZXZpc2F2ZUBvZm5p";

class App {
	private els: Array<HTMLAnchorElement>;
	private done: boolean = false;

	public constructor() {
		this.els = [];
		const els = document.querySelectorAll("[data-email]");
		els.forEach((el: HTMLAnchorElement) => {
			this.els.push(el);
		});

		window.addEventListener("mousemove", this.doEmails.bind(this));
		window.addEventListener("touchstart", this.doEmails.bind(this));
	}

	public doEmails(): void {
		if (this.done) return;

		this.done = true;
		this.els.forEach((el: HTMLAnchorElement) => {
			this.setupEmail(el);
		});

		window.removeEventListener("mousemove", this.doEmails.bind(this));
		window.removeEventListener("touchstart", this.doEmails.bind(this));
	}

	public setupEmail(el: Element): void {
		let em = this.reverse(window.atob(email));
		(el as any).href = `mailto:${em}`;
		(el as any).innerText = em;
	}

	public reverse(s: string) {
		return s
			.split("")
			.reverse()
			.join("");
	}
}

const app = new App();
